import { Route, UrlSegment, UrlSegmentGroup } from '@angular/router';

// A shameless copy from
// https://stackoverflow.com/questions/36154672/angular2-make-route-paths-case-insensitive/47428947#47428947
// as this is exactly what we need and sadly Angular doesn't have a better built in mechanism for matching
// urls params in a case insensitive fashion
export function caseInsensitiveMatcher(url: string) {
  return function(segments: UrlSegment[], segmentGroup: UrlSegmentGroup, route: Route) {
    const matchSegments = url.split('/');
    if (matchSegments.length > segments.length || (matchSegments.length !== segments.length && route.pathMatch === 'full')) {
      return null;
    }

    const consumed: UrlSegment[] = [];
    const posParams: { [name: string]: UrlSegment } = {};
    for (let index = 0; index < matchSegments.length; ++index) {
      const segment = segments[index].toString().toLowerCase();
      const matchSegment = matchSegments[index];

      if (matchSegment.startsWith(':')) {
        posParams[matchSegment.slice(1)] = segments[index];
        consumed.push(segments[index]);
      } else if (segment.toLowerCase() === matchSegment.toLowerCase()) {
        consumed.push(segments[index]);
      } else {
        return null;
      }
    }

    return { consumed, posParams };
  };
}
