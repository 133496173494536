import { BasicTemplateComponent, LandingTemplateComponent, RightAsideTemplateComponent, StandardTemplateComponent } from 'vnext-widgets';

const templateMapping = {
  'basic': BasicTemplateComponent,
  'standard': StandardTemplateComponent,
  'landing': LandingTemplateComponent,
  'right-aside': RightAsideTemplateComponent
};

export class TemplateLookupService {
  static getTemplate(key: string) {
    const template = templateMapping[key.toLowerCase()];
    if (!template) {
      console.log('Unable to find template with key: ' + key);
    }
    return template;
  }
}
