<div *ngIf="states && states.length">
  <div class="live-edit-selector-container container-fluid">
    <div class="col text-center">
      <label class="Spa_Style_H3 d-flex-inline align-items-center"
        >{{ stateSelectorLabel }}
        <select class="form-control w-auto ml-3 d-inline" (change)="onSelectState($event.target.value)">
          <option *ngFor="let state of states" [value]="state.name" [selected]="state.name === selectedState.name">
            {{ state.friendlyName }}
          </option>
        </select>
      </label>
    </div>
  </div>
  <div class="live-edit-selector-placeholder">
    <!-- DO NOT DELETE. Adds extra whitespace at bottom of page since state selector is fixed and will cover bottom of page -->
  </div>
</div>
