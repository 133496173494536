import { ModuleWithProviders, NgModule } from '@angular/core';
import config from 'src/assets/config.json';
import { environment } from 'src/environments/environment';
import { ComponentsModule, ConfigsModule, PipesModule, TemplatesModule, WidgetsModule, ZonesModule } from 'vnext-widgets';

@NgModule({
  imports: [WidgetsModule.forRoot(environment, config)],
  exports: [TemplatesModule, ZonesModule, WidgetsModule, PipesModule, ConfigsModule, ComponentsModule]
})
/**
 * The reusable components, services, pipes, and directives that make up the building blocks of a vnext application.
 */
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        /* Add exported services here; PlansService */
      ]
    };
  }
}
