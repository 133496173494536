import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { Store } from '@ngxs/store';
import _get from 'lodash.get';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import {
  AppThemeService,
  AUTH_STATUS_CODES,
  ConfigResourceService,
  LocaleService,
  NavigationContext,
  PlanYearResourceService,
  PlanYearService,
  ResponsiveLayoutService,
  RuntimeState,
  SiteService,
  SiteType,
  SNPService,
  SpinnerEvent,
  SpinnerService,
  WeglotService
} from 'vnext-shared';
import { SessionIdleService } from './core/session/idle/idle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  showLoaderMessage: boolean;
  loaderText: string = 'Loading...';
  loaderIcon: string;
  iconEnabled: boolean;
  currentLanguage: string;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationContext: NavigationContext,
    private readonly applicationTheme: AppThemeService,
    private readonly responsiveLayoutService: ResponsiveLayoutService,
    private readonly sessionIdleService: SessionIdleService,
    private readonly spinnerService: SpinnerService,
    private readonly configResourceService: ConfigResourceService,
    private readonly localeService: LocaleService,
    private readonly snpService: SNPService,
    private readonly siteService: SiteService,
    private readonly planYearResourceService: PlanYearResourceService,
    private readonly planYearService: PlanYearService,
    private readonly weglotService: WeglotService,
    private readonly store: Store
  ) {
    setTimeout(() => {
      document.querySelector('#initial-la-ball-spin-clockwise')?.remove();
    });
  }

  ngOnInit(): void {
    this.sessionIdleService.initializeIdle();
    this.planYearResourceService.initializeSelectedPlanYear();
    this.responsiveLayoutService.isCompact$.subscribe({
      next: compact => {
        this.applicationTheme.initCreateAppTheme(compact);
      }
    });
    this.iconEnabled = this.configResourceService.getProcessedResourceValue('Spa_Site_Loader_Updated_Icon_Enabled', null, false);
    this.showLoaderMessage =
      this.iconEnabled && this.configResourceService.getProcessedResourceValue('Spa_Site_Loader_Message_Enabled', null, true);

    this.subscriptions.add(
      this.router.events.pipe(filter<NavigationStart>(event => event instanceof NavigationStart)).subscribe({
        next: event => {
          if (this.showLoaderMessage) {
            this.loaderText = this.spinnerService.getLoaderMessage(
              event.url,
              this.configResourceService.getProcessedResourceValue('Spa_Site_Loader_Message_Json', null, [])
            );
          }

          // Second parameter (name) can't be null, because that's causing a non show up Spiner
          this.spinnerService.show(SpinnerEvent.NAVIGATION, undefined, this.loaderText);
        }
      })
    );

    // listening for when we navigate to a new page so we can update the document title and when
    // we change locale
    this.subscriptions.add(
      combineLatest([
        this.router.events.pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map(route => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter(route => route.outlet === 'primary'),
          mergeMap(route => route.data)
        ),
        this.localeService.getCurrentLocale$()
      ]).subscribe({
        next: ([event]) => {
          this.spinnerService.hide(SpinnerEvent.NAVIGATION);
          this.loaderText = null;
          const processedEventTitle = this.configResourceService.processValueProviders(event.title);
          this.titleService.setTitle(processedEventTitle || event.title);
        }
      })
    );

    this.subscriptions.add(
      this.router.events.pipe(filter(event => event instanceof NavigationCancel)).subscribe({
        next: () => {
          this.spinnerService.hide(SpinnerEvent.NAVIGATION);
          this.loaderText = null;
        }
      })
    );

    this.subscriptions.add(
      this.spinnerService
        .getLoaderText$()
        .pipe(filter(loaderText => loaderText !== this.loaderText))
        .subscribe({
          next: loaderText => {
            this.loaderText = loaderText;
          }
        })
    );

    // Need to store our url params on route change since ActivatedRoute only contains the route information about
    // components loaded in an router outlet. Since we need to access url params from other parts of the application
    // we need to watch for route changes and store the relevant information. Read more at:
    // https://stackoverflow.com/questions/42947133/parent-components-gets-empty-params-from-activatedroute
    this.subscriptions.add(
      this.router.events.pipe(filter(event => event instanceof RoutesRecognized)).subscribe({
        next: event => {
          this.navigationContext.setUrlParams(_get(event, 'state.root.firstChild.params'));
          this.navigationContext.setQueryParams(_get(event, 'state.root.firstChild.queryParams'));
        }
      })
    );

    this.subscriptions.add(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
        next: event => {
          this.navigationContext.setUrl(_get(event, 'urlAfterRedirects'));
        }
      })
    );

    this.initializeLocale();
    this.initializeSNPTypeVisibility();
    this.planYearService.initializePlanYearPermissionMediator();
    this.loaderIcon = this.iconEnabled ? 'ball-spin-clockwise' : 'ball-scale-multiple';

    const injectJQuery = this.configResourceService.getProcessedResourceValue('Spa_Site_Enable_JQuery', null, false);
    if (injectJQuery) {
      this.injectJqueryToHead();
    }

    const injectGoogleTranslate = this.configResourceService.getProcessedResourceValue('Spa_Site_Enable_GoogleTranslate', null, false);
    if (injectGoogleTranslate) {
      this.injectGoogleTranslate();
    }

    const siteType = this.configResourceService.getProcessedResourceValue('Spa_Site_SiteType', null, SiteType.CONSUMER);
    // performance: preload demographic background images
    if (!this.siteService.isPPCSite(siteType)) {
      this.preloadImages();
    }

    const isConsumerSiteIdEnabled = this.configResourceService.getProcessedResourceValue('Spa_ConsumerSiteID_Enabled', null, false);
    if (isConsumerSiteIdEnabled) {
      this.store
        .select(RuntimeState.authStatus)
        .pipe(
          filter(authStatus => authStatus === AUTH_STATUS_CODES.JWT_LOADED || authStatus === AUTH_STATUS_CODES.JWT_SAVED),
          take(1)
        )
        .subscribe({
          next: consumerSiteUrlItems => {
            this.siteService.initializeConsumerSiteUrls();
          }
        });
    }

    const agencyConsumerResourceEnabled = this.configResourceService.getProcessedResourceValue(
      'Spa_Agency_Consumer_Resource_Sharing_Enabled',
      null,
      false
    );
    const agencyContactConsumerResourceEnabled = this.configResourceService.getProcessedResourceValue(
      'Spa_AgencyContact_Consumer_Resource_Sharing_Enabled',
      null,
      false
    );
    if (agencyConsumerResourceEnabled && agencyContactConsumerResourceEnabled) {
      this.siteService.initializeContactInfoResource();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected injectJqueryToHead(): void {
    this.injectScriptToHead('https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js');
  }

  protected injectGoogleTranslate(): void {
    this.injectGoogleTranslateConfiguration();
    this.injectScriptToHead('https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
  }

  private preloadImages(): void {
    const homePageBackgroundImageDesktop = this.configResourceService.getProcessedResourceValue('Spa_Demographic_Background_Url', null, '');
    const homePageBackgroundImageMobile = this.configResourceService.getProcessedResourceValue(
      'Spa_Demographic_Background_UrlMobile',
      null,
      ''
    );

    this.injectPreloadLinkToHead(homePageBackgroundImageDesktop, 'image');
    this.injectPreloadLinkToHead(homePageBackgroundImageMobile, 'image');
  }

  private initializeLocale(): void {
    const weglotMultiLanguageEnabled = this.configResourceService.getProcessedResourceValue(
      'Spa_Weglot_MultiLanguage_Enabled',
      null,
      false
    );

    // we cannot used activatedRoute in a component that is not rendered in the router-outlet chain
    // which AppComponent can't be since it is the component to define the router-outlet
    // therefore we will use native JS to get the culture code so we can set it immediately
    // as setting it later results in the locale pickers lagging behind the locale change
    const urlParams = new URLSearchParams(window.location.search);
    const queryParamsCultureCode = urlParams.get('CultureCode') || urlParams.get('lang');
    const currentLocale = queryParamsCultureCode ?? this.localeService.getCurrentLocale();
    if (!weglotMultiLanguageEnabled && currentLocale && this.localeService.isValidLocale(currentLocale)) {
      this.localeService.setLocale(currentLocale);
    } else {
      this.initializeDefaultLocale();
    }

    if (weglotMultiLanguageEnabled) {
      this.weglotService.setDefaultLocale();
      this.weglotService.setLocale(queryParamsCultureCode);
    }
  }

  private initializeSNPTypeVisibility(): void {
    const defaultVisibility: boolean = this.configResourceService.getProcessedResourceValue(
      'Spa_Site_ShowSNPPlansDefault_Flag',
      null,
      false
    );
    this.snpService.initializeSNPTypeVisibility(defaultVisibility);
  }

  /**
   * Set the default locale using Spa_Site_Default_Locale, then the browser language, then the application default
   */
  private initializeDefaultLocale(): void {
    const siteDefaultLocale = this.configResourceService.getProcessedResourceValue('Spa_Site_Default_Locale', null, 'en-US');
    if (siteDefaultLocale && this.localeService.isValidLocale(siteDefaultLocale)) {
      this.localeService.setLocale(siteDefaultLocale);
    } else {
      const browserLanguage = navigator?.language;
      if (browserLanguage && this.localeService.isValidLocale(siteDefaultLocale)) {
        this.localeService.setLocale(browserLanguage);
      } else {
        this.localeService.setLocale(this.localeService.getDefaultLocale());
      }
    }
  }

  private injectScriptToHead(src: string, onload?): void {
    if (src) {
      const scriptElement: HTMLScriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = src;
      scriptElement.async = false;
      if (onload) {
        scriptElement.onload = onload;
      }
      // find old script to replace if there
      const currentScript = document.querySelector(`script[src='${src}']`);
      if (currentScript) {
        currentScript.parentNode.removeChild(currentScript);
      }
      document.head.append(scriptElement);
    }
  }

  private injectGoogleTranslateConfiguration(): void {
    const scriptElement: HTMLScriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.innerHTML = `function googleTranslateElementInit() {
          new google.translate.TranslateElement({ pageLanguage: 'en', layout: google.translate.TranslateElement.InlineLayout.VERTICAL }, 'google_translate_element');
        }`;
    document.head.append(scriptElement);
  }

  private injectPreloadLinkToHead(href: string, as: string, onload?: (ev: Event) => any): void {
    if (href) {
      const linkElement: HTMLLinkElement = document.createElement('link');
      linkElement.href = href;
      linkElement.rel = 'preload';
      linkElement.as = as;
      if (onload) {
        linkElement.onload = onload;
      }
      // find old link to replace if there
      const currentLink = document.querySelector(`link[href='${href}']`);
      if (currentLink) {
        currentLink.parentNode.removeChild(currentLink);
      }
      document.head.append(linkElement);
    }
  }
}
