import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  ApplicationState,
  ConfigResourceService,
  LiveEditApplicationState,
  LiveEditStateContext,
  LiveEditStateLookUpService,
  LiveEditStateSelectorService,
  NavigationUtilityService,
  SetDefaultApplicationState
} from 'vnext-shared';

@Component({
  selector: 'vnext-live-edit-state-selector',
  templateUrl: './live-edit-state-selector.component.html',
  styleUrls: ['./live-edit-state-selector.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiveEditStateSelectorComponent implements OnInit {
  states: LiveEditApplicationState[];
  selectedState: LiveEditApplicationState;
  stateSelectorLabel: string = 'Select a state:';

  constructor(
    private cdr: ChangeDetectorRef,
    private navigationUtilityService: NavigationUtilityService,
    private liveEditStateSelectorService: LiveEditStateSelectorService,
    private configResourceService: ConfigResourceService,
    private liveEditStateLookupService: LiveEditStateLookUpService,
    private liveEditStateContext: LiveEditStateContext,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.stateSelectorLabel = this.configResourceService.getProcessedResourceValue(
      'Spa_LiveEdit_StateSelector_LabelText',
      null,
      'Select a state:'
    );

    this.initializeDefaultApplicationState();
    this.initializeSelector();
  }

  onSelectState(stateName: string): void {
    this.selectedState = this.states.find(state => state.name === stateName);
    this.applySelectedState(this.selectedState);
  }

  private initializeDefaultApplicationState(): void {
    const defaultApplicationState: any = this.liveEditStateSelectorService.getDefaultApplicationState();
    if (!defaultApplicationState || defaultApplicationState?.appConfig?.pages?.length === 0) {
      this.setDefaultApplicationState();
    }
  }

  private initializeSelector(): void {
    const currentPage = this.navigationUtilityService.getCurrentPage();
    const pageTags = currentPage && currentPage.tags ? currentPage.tags : null;
    if (pageTags) {
      this.states = this.liveEditStateLookupService.getStates(pageTags);
      if (this.states && this.states.length) {
        const currentSelectedState = this.liveEditStateContext.currentLiveEditState;

        if (currentSelectedState) {
          this.selectedState = currentSelectedState;
        } else {
          this.selectedState = this.states.find(state => state.name === 'Default');

          // Apply the default state (set Store Overrides) when the default state has store data overrides
          if (this.selectedState?.storeDataOverrides?.length > 0) {
            this.applySelectedState(this.selectedState);
          }
        }
      }
    }
  }

  private setDefaultApplicationState(): void {
    const applicationState = this.store.selectSnapshot(ApplicationState);
    this.store.dispatch(new SetDefaultApplicationState(applicationState));
  }

  private applySelectedState(selectedState: LiveEditApplicationState): void {
    if (selectedState) {
      this.liveEditStateSelectorService.applyState(selectedState);
      this.cdr.markForCheck();
    }
  }
}
