import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { merge, of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { AppConfigModel, AppConfigState, AuthService, ConfigLoaderService, SetConfig, SpinnerEvent, SpinnerService } from 'vnext-shared';

@Component({
  selector: 'vnext-live-edit-root',
  templateUrl: './live-edit-root.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * The LiveEditRootComponent acts as the parent route for all of the dynamically generated components in live edit.
 * This loads our dynamic config in one of two ways.
 *
 * 1) If we directly attempt to hit a child route this will check if we have loaded our app config into session storage.
 *    If the config has not been loaded into session storage we will check for a valid JWT in localStorage and load it from platform.
 * 2) This will observe if our JWT has changed in localStorage. If so, then we will attempt to reload the config from platform as the
 *    user may no longer be allowed to access the config.
 *
 * Because parent routes are loaded before their children this will ensure all child routes will run this logic even if we directly
 * access them.
 */
export class LiveEditRootComponent implements OnInit {
  constructor(
    private configLoaderService: ConfigLoaderService,
    private store: Store,
    private authService: AuthService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this.store.select(AppConfigState.loading).subscribe({
      next: loading => (
        loading ? this.spinnerService.show(SpinnerEvent.APP_CONFIG_LOADING) : this.spinnerService.hide(SpinnerEvent.APP_CONFIG_LOADING )
      )
    });

    merge(this.store.select(AppConfigState.loaded), this.authService.observeForJWT())
      .pipe(
        filter(response => this.isJWT(response) || this.isLoaded(response)),
        tap(() => this.spinnerService.show(SpinnerEvent.APP_CONFIG_LOADING)),
        switchMap(() => this.configLoaderService.loadDynamicConfig$()),
        catchError(e => of(false)),
        filter(response => response !== false),
        switchMap(configuration => this.store.dispatch(new SetConfig(configuration as AppConfigModel)))
      )
      .subscribe({
        next: () => {
          this.spinnerService.hide(SpinnerEvent.APP_CONFIG_LOADING);
          window.location.reload();
        }
      });
  }

  /**
   * Predicate to determine if the incoming response is a JWT
   */
  private isJWT = (jwt): boolean => typeof jwt === 'string';

  /**
   * Predicate to determine if the incoming response is loaded.
   */
  private isLoaded(loaded): boolean {
    return typeof loaded === 'boolean' && loaded === false && this.authService.getToken() !== null;
  }
}
