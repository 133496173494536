import { ErrorHandler, Injectable } from '@angular/core';
import { ExternalErrorLoggingService } from 'vnext-shared';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private readonly externalErrorLoggingService: ExternalErrorLoggingService) {}
  handleError(error: Error) {
    console.error(error);
    this.externalErrorLoggingService.log(error);
  }
}
