import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { NGXS_LOGGER_PLUGIN_OPTIONS, NgxsLoggerPluginOptions } from '@ngxs/logger-plugin';
import { NGXS_PLUGINS } from '@ngxs/store';
import { VnextStorageLoggerService } from '../vnext-storage-logger.service';

export const USER_OPTIONS: InjectionToken<NgxsLoggerPluginOptions> = new InjectionToken<NgxsLoggerPluginOptions>(
  'VNEXT_LOGGER_USER_OPTIONS'
);
export const DEFAULT_LOGGER_OPTIONS: NgxsLoggerPluginOptions = {
  logger: console,
  collapsed: false,
  disabled: false,
  filter: action => true
};

export function loggerOptionsFactory(options: NgxsLoggerPluginOptions) {
  return { ...DEFAULT_LOGGER_OPTIONS, ...options };
}

@NgModule()
export class VnextLoggerPluginModule {
  static forRoot(options?: NgxsLoggerPluginOptions): ModuleWithProviders<VnextLoggerPluginModule> {
    return {
      ngModule: VnextLoggerPluginModule,
      providers: [
        {
          provide: NGXS_PLUGINS,
          useClass: VnextStorageLoggerService,
          multi: true
        },
        {
          provide: USER_OPTIONS,
          useValue: options
        },
        {
          provide: NGXS_LOGGER_PLUGIN_OPTIONS,
          useFactory: loggerOptionsFactory,
          deps: [USER_OPTIONS]
        }
      ]
    };
  }
}
