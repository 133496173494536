import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'vnext-widgets';

export const DEFAULT_ROUTES: Routes = [
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404' }
];

const ROUTER_OPTIONS: ExtraOptions = {
  onSameUrlNavigation: 'reload',
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64]
};

@NgModule({
  // don't need to enableTracing because we are using NGXS router for storing router state
  imports: [RouterModule.forRoot(DEFAULT_ROUTES, ROUTER_OPTIONS)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
