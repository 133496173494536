import { Inject, Injectable, Injector } from '@angular/core';
import { NGXS_LOGGER_PLUGIN_OPTIONS, NgxsLoggerPlugin, NgxsLoggerPluginOptions } from '@ngxs/logger-plugin';
import { NgxsNextPluginFn } from '@ngxs/store';

export const VNEXT_STORAGE_LOGGER_DISABLED_KEY: string = 'vnextStorageLoggerDisabled';

@Injectable({
  providedIn: 'root'
})
export class VnextStorageLoggerService extends NgxsLoggerPlugin {
  static configure(isProductionEnvironment: boolean): void {
    try {
      const vnextStorageLoggerDisabled: boolean = JSON.parse(sessionStorage.getItem(VNEXT_STORAGE_LOGGER_DISABLED_KEY));

      window.__vnextStorageLoggerDisabled = vnextStorageLoggerDisabled ?? isProductionEnvironment;
    } catch (error) {
      window.__vnextStorageLoggerDisabled = isProductionEnvironment;
    }
  }

  constructor(@Inject(NGXS_LOGGER_PLUGIN_OPTIONS) private readonly options: NgxsLoggerPluginOptions, private readonly injector: Injector) {
    super(options, injector);
  }

  handle(state: any, event: any, next: NgxsNextPluginFn): any {
    if (window.__vnextStorageLoggerDisabled) {
      return next(state, event);
    }

    return super.handle(state, event, next);
  }
}
