<vnext-page-spinner
  [loaderText]="loaderText"
  [loaderIcon]="loaderIcon"
  [showLoaderMessage]="showLoaderMessage"
>
</vnext-page-spinner>
<div class="vnext-app">
  <div id="google_translate_element" style="position: absolute; z-index: 10000; right: 0"></div>
  <router-outlet></router-outlet>
</div>
