import { APP_BASE_HREF, PlatformLocation, registerLocaleData, ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import esMXLocale from '@angular/common/locales/es-MX';
import zhLocale from '@angular/common/locales/zh';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router, Scroll } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleModule } from '@ng-idle/core';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  AgentState,
  AppConfigState,
  ApplicationState,
  AuthRequestInterceptor,
  AuthResponseInterceptor,
  BlueButtonMedicineCabinetState,
  CacheInterceptor,
  ConfigResourceService,
  ConfirmationState,
  DeepLinkingParamsState,
  DemographicState,
  EnrollmentState,
  GoogleTagManagerState,
  GuidedHelpState,
  HelpfulToolsState,
  ImageService,
  JsonAnalyticsTagManagerState,
  LanguageInterceptor,
  LiveEditState,
  MapState,
  MedicineCabinetState,
  MedSupState,
  MemberState,
  MillimanState,
  PharmacyState,
  PlansState,
  PlanYearInterceptor,
  PreferencesState,
  ProfileState,
  ProviderState,
  QuoteBeneficiaryState,
  RouteOptionsService,
  RuntimeState,
  ShoppingCartState,
  SiteState,
  SOAState,
  TwilioState,
  UiState,
  VnextSharedModule
} from 'vnext-shared';
import config from '../assets/config.json';
import { AppRoutingModule, DEFAULT_ROUTES } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { RouteBuilder } from './core/routes/route-generator';
import { GlobalErrorHandler } from './core/services/global-error-handler.service';
import { VnextLoggerPluginModule } from './core/store/logger/vnext-logger-plugin/vnext-logger-plugin.module';
import { VNextStorageEngine } from './core/store/vnext-storage-engine';
import { SharedModule } from './shared/shared.module';

// TODO: can we load these in a different file?
registerLocaleData(esMXLocale);
registerLocaleData(zhLocale);

VnextSharedModule.loadConfig(environment, config);

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgbModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxsModule.forRoot(
      [
        AgentState,
        ApplicationState,
        AppConfigState,
        ConfirmationState,
        RuntimeState,
        MemberState,
        DemographicState,
        ProfileState,
        PlansState,
        PharmacyState,
        ProviderState,
        EnrollmentState,
        MedicineCabinetState,
        PreferencesState,
        BlueButtonMedicineCabinetState,
        HelpfulToolsState,
        MedSupState,
        GuidedHelpState,
        MapState,
        UiState,
        ShoppingCartState,
        SOAState,
        QuoteBeneficiaryState,
        MillimanState,
        DeepLinkingParamsState,
        GoogleTagManagerState,
        JsonAnalyticsTagManagerState,
        LiveEditState,
        TwilioState,
        SiteState
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          suppressErrors: false,
          injectContainerState: false
        }
      }
    ),
    NgxsStoragePluginModule.forRoot({
      // if we don't specify the exct stores we need to store in sessionStorage the routing module
      // will also be serialized and stored which messes up routing on reloads
      key: ['application', 'ui', 'liveEdit']
    }),
    NgxsRouterPluginModule.forRoot(),
    AppRoutingModule,
    // allows us to import SharedModule services to be provided at the AppModule level
    SharedModule.forRoot(),
    CoreModule,
    // TODO: configure this to log to the console in dev mode and allow
    // the user to config where this is logged to eventually
    VnextLoggerPluginModule.forRoot(),
    NgIdleModule.forRoot(),
    InfiniteScrollModule,
    VnextSharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PlanYearInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    // we are localizing messages via the resources in the platform config file but this locale will
    // determine how dates, currency, decimals and percents are localized via their respective pipes
    // TODO: add local resolution mechanism
    { provide: LOCALE_ID, useValue: 'en-US' },
    // TODO: use APP_INITIALIZER and set config in store with an action OR PLATFORM_CONFIG using DI
    // {provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig], multi: true }
    {
      provide: APP_BASE_HREF,
      useFactory: (platformLocation: PlatformLocation) => platformLocation.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    },
    {
      provide: STORAGE_ENGINE,
      useClass: VNextStorageEngine
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent],
  exports: [SharedModule]
})
export class AppModule {
  constructor(
    private readonly router: Router,
    private readonly configResourceService: ConfigResourceService,
    private readonly store: Store,
    private readonly routeOptionsService: RouteOptionsService,
    private readonly imageService: ImageService,
    private readonly viewportScroller: ViewportScroller
  ) {
    const routes = this.router.config;
    const configRoutes = RouteBuilder.build(environment.liveedit ? {} : config, {
      disabledRoutes: this.routeOptionsService.disabledRoutes
    });
    router.events.pipe(filter(e => e instanceof Scroll)).subscribe({
      next: (e: Scroll) => {
        if (e.anchor) {
          setTimeout(() => {
            viewportScroller.scrollToAnchor(e.anchor);
          });
        }
      }
    });
    // merging in dynamic routes on app load
    routes.unshift(...configRoutes);
    this.router.resetConfig(routes);

    // rebuild routes if the config pages or homepage changes in live edit
    // this should not happen in development as we are sourcing the pages and homepage from a static file
    // this can however happen in liveedit as we are calling a service to retrieve configuration data
    if (environment.liveedit) {
      this.store.select(AppConfigState.pages).subscribe({
        next: pages => {
          const homepage = this.store.selectSnapshot(AppConfigState.homepage);
          const defaultRoutes = [...DEFAULT_ROUTES];
          defaultRoutes.unshift(
            ...RouteBuilder.build(
              { pages, homepage },
              {
                disabledRoutes: []
              }
            )
          );
          this.router.resetConfig(defaultRoutes);
        }
      });

      this.store.select(AppConfigState.homepage).subscribe({
        next: homepage => {
          const pages = this.store.selectSnapshot(AppConfigState.pages);
          const defaultRoutes = [...DEFAULT_ROUTES];
          defaultRoutes.unshift(
            ...RouteBuilder.build(
              { pages, homepage },
              {
                disabledRoutes: []
              }
            )
          );
          this.router.resetConfig(defaultRoutes);
        }
      });
    }
  }
}
