import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LiveEditRootComponent } from './live-edit/live-edit-root/live-edit-root.component';
import { LiveEditStateSelectorComponent } from './live-edit/live-edit-state-selector/live-edit-state-selector.component';

@NgModule({
  declarations: [LiveEditRootComponent, LiveEditStateSelectorComponent],
  imports: [CommonModule, RouterModule],
  exports: [],
  providers: [
    /* Eventually add core services like auth service here */
  ]
})
/**
 * The core module contains singleton services ( like auth ) and single use components ( like page-not-found )
 */
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('Core module should only be imported once');
    }
  }
}
